@use "sass:math";
@tailwind base;
@tailwind components;
@tailwind utilities;
.imgpath {
  mask-image: url("./images/Vector.png");
  mask-size: cover;
}
.topVector {
  mask-image: url("./images/topvector.svg");
  mask-size: cover;
}
.bottomVector {
  mask-image: url("./images/bottomvector.svg");
  mask-size: cover;
}

.circle-container {
  $particleNum: 100;
  $particleColor: #1e2f6a;

  position: absolute;
  transform: translateY(-10vh);
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  .circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    mix-blend-mode: screen;
    background-image: radial-gradient(
      $particleColor,
      $particleColor 10%,
      hsla(180, 9%, 76%, 0) 56%
    );

    animation: fadein-frames 0ms infinite, scale-frames 2s infinite;

    @keyframes fade-frames {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0.7;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes scale-frames {
      0% {
        transform: scale3d(0.4, 0.4, 1);
      }

      50% {
        transform: scale3d(2.2, 2.2, 1);
      }

      100% {
        transform: scale3d(0.4, 0.4, 1);
      }
    }
  }

  $particleBaseSize: 8;

  @for $i from 1 through $particleNum {
    &:nth-child(#{$i}) {
      $circleSize: math.random($particleBaseSize);
      width: $circleSize + px;
      height: $circleSize + px;

      $startPositionY: math.random(10) + 100;
      $framesName: "move-frames-" + $i;
      $moveDuration: 28000 + math.random(9000) + ms;

      animation-name: #{$framesName};
      animation-duration: $moveDuration;
      animation-delay: math.random(37000) + ms;

      @keyframes #{$framesName} {
        from {
          transform: translate3d(
            math.random(100) + vw,
            $startPositionY + vh,
            0
          );
        }

        to {
          transform: translate3d(
            math.random(100) + vw,
            -$startPositionY - math.random(30) + vh,
            0
          );
        }
      }

      .circle {
        animation-delay: math.random(4000) + ms;
      }
    }
  }
}

// Animation of footer logo
.animation {
  height: 50px;
  overflow: hidden;
}

.animation > div > p {
  padding: 0.25rem 0.75rem;
  height: 2.81rem;
  margin-top: 4px;
  margin-bottom: 2.81rem;
  display: inline-block;
}

.animation div:first-child {
  animation: text-animation 8s infinite;
}

@keyframes text-animation {
  0% {
    margin-top: 0;
  }
  10% {
    margin-top: 0;
  }
  20% {
    margin-top: -5.62rem;
  }
  30% {
    margin-top: -5.62rem;
  }
  40% {
    margin-top: -11.24rem;
  }
  60% {
    margin-top: -11.24rem;
  }
  70% {
    margin-top: -5.62rem;
  }
  80% {
    margin-top: -5.62rem;
  }
  90% {
    margin-top: 0;
  }
  100% {
    margin-top: 0;
  }
}

// waves animation

.moveRightWave {
  animation: moveWaveRight 5s linear infinite forwards;
  transform: translateX(-50%);
}

.moveLeftWave {
  animation: moveWaveRight 8s linear infinite reverse;
}

@keyframes moveWaveRight {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}
